import React, { useEffect, useState } from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import { PrismicRichText } from '@prismicio/react';
import { useSelector } from 'react-redux';

import SpinnerContainer from '../../../SpinnerContainer';
import { AlertContainer } from '../../Registration/Alert';
import StripeCheckoutButton from '../../Registration/StripeCheckoutButton';
import redExclamation from '../../../../images/icons/red-exclamation-point.svg';
import {
  INVOICE_STATUS_SPONSOR_VERIFYING,
  PAYABLE_INVOICE_STATUSES
} from '../../../../utils/constants';
import Invoice from '../../Registration/InvoiceDisplay/Invoice';
import linkResolver from '../../../../utils/linkResolver';

const InvoiceListContainer = styled.div`
  margin-bottom: 64px;
  max-width: 856px;
`;

const PaymentsMessage = styled.div`
  p {
    font-weight: ${prop('theme.fontWeights.light')};
    line-height: 24px;
  }

  a {
    color: ${prop('theme.colors.redPrimary')};
    font-weight: ${prop('theme.fontWeights.medium')};
  }
`;

const AlertWrapper = styled(AlertContainer)`
  font-weight: ${prop('theme.fontWeights.light')};
  font-size: 1rem;
  margin-bottom: 16px;

  img {
    margin-right: 30px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
`;

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: {}
};

function InvoiceList({ data }) {
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const { invoices } = useSelector((state) => get(state, 'memberInfo', []));
  const {
    action_text_block,
    alert_text,
    payment_button_text,
    text_block
  } = get(data, 'primary');
  const invoice = invoices[0];

  if (!invoice) {
    return null;
  }

  const isPayable = PAYABLE_INVOICE_STATUSES.includes(invoice.status);
  const isSponsorVerifying =
    INVOICE_STATUS_SPONSOR_VERIFYING === invoice.status;
  const isIncomplete = isPayable || isSponsorVerifying;

  useEffect(() => {
    if (invoices.length === 0) {
      setInvoicesLoading(true);
      dispatch(fetchInvoices(() => setInvoicesLoading(false)));
    }
  }, []);

  return (
    <InvoiceListContainer>
      <PaymentsMessage>
        {isSponsorVerifying ? (
          <PrismicRichText
            field={action_text_block?.richText}
            linkResolver={linkResolver}
          />
        ) : (
          <PrismicRichText
            field={text_block?.richText}
            linkResolver={linkResolver}
          />
        )}
      </PaymentsMessage>
      {invoicesLoading ? (
        <SpinnerContainer height='500'>
          <Spinner style={{ width: '64px', height: '64px' }} />
        </SpinnerContainer>
      ) : (
        <>
          {isIncomplete && (
            <AlertWrapper $borderColor='redPrimary'>
              <img src={redExclamation} />
              {alert_text.text}
            </AlertWrapper>
          )}
          <Invoice invoice={invoice} locked={isSponsorVerifying} />
          {isPayable && (
            <ButtonWrapper>
              <StripeCheckoutButton buttonText={payment_button_text} />
            </ButtonWrapper>
          )}
        </>
      )}
    </InvoiceListContainer>
  );
}

InvoiceList.propTypes = propTypes;
InvoiceList.defaultProps = defaultProps;

export default InvoiceList;
